import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import React from "react"
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa"

function footer(props) {
  return (
    <div>
      <div className=" bg-purple">
        <div className=" container w-full mx-auto px-10 max-sm:px-5 pt-20">
          <div className="gap-20 flex max-sm:block ">
            <div className="flex justify-center md:justify-start">
              <div>
                <StaticImage
                  src="../images/Logo.png"
                  className=" w-40"
                  alt="Hummingbird MICE Sri Lanka"
                  placeholder="blurred"
                />
              </div>
            </div>
            <div className=" min-w-fit max-sm:pt-10 text-center sm:text-left">
              <div className=" text-ivory text-xl font-optima-bold ">
                Sitemap
              </div>
              <div className=" break-normal mt-5 space-y-5 text-ivory/70">
                <div className="hover:text-ivory cursor-pointer">
                  <Link to="/">Home</Link>
                </div>
                <div className="hover:text-ivory cursor-pointer font-optima">
                  <Link to="/past-humming-bird-events">Our Past tours</Link>
                </div>
                <div className="hover:text-ivory cursor-pointer font-optima">
                  <Link to="/our-story">Our Story</Link>
                </div>
                <div className="hover:text-ivory cursor-pointer font-optima">
                  <Link to="/sustainability-and-philanthropy">
                    Sustainability
                  </Link>
                </div>
                <div className="hover:text-ivory cursor-pointer font-optima">
                  <Link to="/mice-events-that-we-do">Events</Link>
                </div>
                <div className="hover:text-ivory cursor-pointer font-optima">
                  <Link to="/contact">Contact</Link>
                </div>
              </div>
            </div>
            <div className=" flex w-full max-sm:pt-10 text-center sm:text-left">
              <div className=" w-full">
                <div className=" text-ivory font-optima-bold text-xl">
                  Leagal
                </div>
                <div className=" mt-5 w-full space-y-5 text-ivory/70">
                  <div className="hover:text-ivory cursor-pointer font-optima">
                    Terms of Services
                  </div>
                  <div className="hover:text-ivory cursor-pointer font-optima">
                    Privacy policy
                  </div>
                </div>
              </div>
              <div className=" w-full flex justify-end text-white max-lg:!hidden">
                <div>
                  <div className="flex gap-10">
                    <div className="grid grid-cols-4 justify-center w-fit h-fit gap-4">
                      {props.footerDataStrapi.map((item, index) => {
                        const footerImages = getImage(item.localFile)
                        return (
                          <div key={item.id} className="w-fit h-fit">
                            <GatsbyImage
                              className="w-fit h-full object-cover"
                              alt="Hummingbird MICE Sri Lanka"
                              image={footerImages}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-ivory w-full pb-20 max-lg:!hidden ">
            <div className=" flex justify-end">
              <div className=" font-optima-bold  text-lg">Follow us on</div>
            </div>
            <div className=" flex gap-5 mt-3 justify-end">
              <div className=" text-ivory cursor-pointer">
                <FaFacebookF />
              </div>
              <div className=" text-ivory cursor-pointer">
                <FaInstagram />
              </div>
              <div className=" text-ivory cursor-pointer">
                <FaTwitter />
              </div>
              <div className=" text-ivory cursor-pointer">
                <FaYoutube />
              </div>
            </div>
          </div>
          <div className="py-20 lg:py-10 w-full hidden text-white max-lg:flex lg:justify-end justify-center lg:px-10">
            <div>
              <div className="flex gap-10">
                <div className="grid grid-cols-4 justify-center w-fit h-fit gap-4">
                  {props.footerDataStrapi.map((item, index) => {
                    const footerImages = getImage(item.localFile)
                    return (
                      <div key={index} className="w-fit h-fit">
                        <GatsbyImage
                          className="w-fit h-fit object-cover"
                          image={footerImages}
                          alt="Hummingbird MICE Sri Lanka"
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="text-ivory w-full hidden lg:mb-0 mb-10 max-lg:grid ">
            <div className="">
              <div className=" font-optima-bold lg:text-left text-center text-lg">
                Follow us on
              </div>
            </div>
            <div className="justify-center lg:justify-start flex gap-5 mt-3 ">
              <div className=" text-ivory cursor-pointer">
                <FaFacebookF />
              </div>
              <div className=" text-ivory cursor-pointer">
                <FaInstagram />
              </div>
              <div className=" text-ivory cursor-pointer">
                <FaTwitter />
              </div>
              <div className=" text-ivory cursor-pointer">
                <FaYoutube />
              </div>
            </div>
          </div>
          <div className="">
            <div className=" bg-ivory/30 w-full  h-[1px]"></div>
            <div className="flex md:flex-row flex-col md:gap-10">
              <div className="text-center text-ivory/70 py-3 text-sm">
                © 2024 Mice | All Rights Reserved
              </div>
              <div className="text-center text-ivory/70 pb-3 md:py-3 text-sm">
                Designed and developed by{" "}
                <a
                  href="http://invosglobal.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-ivory"
                >
                  Invos Global
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default footer
