import * as React from "react"
import Header from "./header"
import Footer from "../components/footer"
import { graphql, useStaticQuery } from "gatsby"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      strapiFooter {
        FooterLogos {
          id
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  const footerDataStrapi = data.strapiFooter.FooterLogos

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer footerDataStrapi={footerDataStrapi} />
    </>
  )
}

export default Layout
