import "./src/styles/global.css"
import ReactDOM from "react-dom/client"
import React from "react"
import Layout from "./src/components/layout"

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
