import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FaBars, FaTimes } from "react-icons/fa"
function Header() {
  const [menubutton, SetMenuButton] = React.useState(false)
  const [menuscroll, SetMenuScroll] = React.useState(false)
  const pageScroll = () => {
    if (window.scrollY > 200) {
      SetMenuScroll(true)
    } else {
      SetMenuScroll(false)
    }
  }
  React.useEffect(() => {
    window.addEventListener("scroll", pageScroll)
    return () => {
      window.removeEventListener("scroll", pageScroll)
    }
  })
  return (
    <div>
      <div
        className={`fixed duration-300 top-0 z-40 w-full ${
          menuscroll
            ? "bg-purple"
            : "bg-gradient-to-b from-black/70 via-black/40 to-transparent"
        }`}
      >
        <div className=" w-full ">
          <div
            onClick={() => SetMenuButton(false)}
            className={`text-ivory text-xl justify-center duration-300 fixed bg-purple w-full text-center flex items-center  h-screen ${
              menubutton ? "bottom-[0%]" : "bottom-[100%]"
            }`}
          >
            <div className=" items-center space-y-5 absolute justify-center font-optima-bold">
              <Link to="/">
                <div
                  className=" cursor-pointer"
                  onClick={() => SetMenuButton(false)}
                >
                  Home
                </div>
              </Link>
              <div
                onClick={() => SetMenuButton(false)}
                className=" cursor-pointer"
              >
                <Link to="/past-humming-bird-events">Our past tours</Link>
              </div>
              <div
                onClick={() => SetMenuButton(false)}
                className=" cursor-pointer"
              >
                <Link to="/our-story">Our Story</Link>
              </div>
              <div
                onClick={() => SetMenuButton(false)}
                className=" cursor-pointer"
              >
                <Link to="/sustainability-and-philanthropy">
                  Sustainability
                </Link>
              </div>
              <div
                onClick={() => SetMenuButton(false)}
                className=" cursor-pointer"
              >
                <Link to="/mice-events-that-we-do">Events</Link>
              </div>
              <div
                onClick={() => SetMenuButton(false)}
                className=" cursor-pointer"
              >
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`container ${
            menuscroll ? "py-2" : "py-5"
          } py-5 mx-auto max-xl:px-5 duration-500`}
        >
          <div className=" grid grid-cols-3 items-center px-10 max-sm:px-0 justify-between max-sm:flex">
            <div className="col-start-2">
              <div className="flex justify-center">
                <Link to="/">
                  <StaticImage
                    src="../images/Logo.png"
                    placeholder="blurred"
                    className={`${menuscroll ? "w-10" : "w-20"} duration-500`}
                    quality={100}
                    alt="Hummingbird MICE Sri Lanka"
                  />
                </Link>
              </div>
            </div>
            <div className=" text-white z-10">
              <div
                onClick={() => SetMenuButton(!menubutton)}
                className="flex justify-end duration-200"
              >
                {menubutton ? (
                  <FaTimes className="text-2xl text-ivory cursor-pointer" />
                ) : (
                  <FaBars className="text-2xl text-ivory cursor-pointer" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
